.cont_auth_layout{
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .background {
    object-fit: cover;
    object-position: center center;
    width: auto;
    min-width: 60%;
    max-width: 60%;
    height: 100%;
  }
  & > div {
    width: 40%;
    padding: 20px;
    align-content: center;
    background: white;
    border-radius: 16px;
  }
  @include tablet {
    .background {
      position: absolute;
      min-width: none;
      max-width: none;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    & > div {
      width: 80%;
      // padding-right: 0;
    }
  }
}