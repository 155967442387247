.cont_facturar_step2_view{
  min-width: 500px;
  h2{
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  .cont_tax_information{
    .cont_form{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      & > div{
        display: flex;
        flex-direction: column;
        input, .ant-select{
          width: 100%;
          min-width: 400px;
          max-width: 400px;
          @include tablet{
            min-width: auto;
            max-width: auto;
          }
        }
      }
    }
  }
  .cont_detail_order {
    margin-top: 20px;
    table{
      border-collapse: separate;
      border-spacing: 5px;
      width: 100%;
      td{
        padding: 5px 0;
        text-align: center;
      }
      tbody{
        tr{
          td{
            text-align: center;
          }
        }
      }
      tfoot{
        tr{
          &:first-child{
            td{
              padding-top: 20px;
            }
          }
          td:first-child{
            font-weight: bold;
            text-align: right;
          }
        }
      }
    }
  }
  .button{
    width: 100%;
    margin-top: 5px;
  }
  @include phone{
    min-width: auto;
    .cont_tax_information{
      .cont_form{
        grid-template-columns: 1fr;
      }
    }
  }
}