.custom_tooltip{
  background-color: rgba($color: #fff, $alpha: 0.9);
  padding: 10px;
  border-radius: 10px;
  .title{
    font-size: 1.4rem;
    font-weight: 600;
    color: #000;
    line-height: 1.5;
  }
  .label{
    font-size: 1.2rem;
    font-weight: 500;
    color: #000;
    line-height: 1.5;
  }
}