table.cont_table_prices{
  width: 100%;
  border-spacing: 0;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  thead{
    background: $primary-color;
    tr{
      th{
        padding: 10px 0;
        font-size: 1.3rem;
        font-weight: 500;
        color: white;
        text-align: center;
        border-bottom: 1px solid #e0e0e0;
        &:first-child{
          border-radius: 20px 0 0 0;
        }
        &:last-child{
          border-radius: 0 20px 0 0;
        }
      }
    }
  }
  tbody{
    tr{
      td{
        padding: 10px 0;
        color: rgb(32, 32, 32);
        text-align: center;
        span{
          font-size: 1.2rem;
          font-weight: 500;
        }
        span.original_price{
          // Make diagonal line through text
          color: rgb(172, 172, 172);
          font-size: 0.9rem;
          text-decoration: line-through;
        }
        span.promotion_price{
          background: linear-gradient(to right, $secondary-color 40%, $primary-color 100%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 5px;
        }
        &.contactanos{
          padding: 20px 0;
          a.contact_us{
            padding: 10px 20px;
            background: linear-gradient(to right, $secondary-color 40%, $primary-color 100%);
            color: #fff;
            border: none;
            border-radius: 50px;
            font-size: 1.3rem;
            font-weight: 500;
            white-space: nowrap;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }
  }
  @include tablet{
    
  }
}