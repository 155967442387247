.cont_layout_home{
  & > header, main > section{
    padding: 20px 6vw;
  }
  header{
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 0;
    position: fixed;
    height: auto;
    max-height: 13vh;
    top: 0;
    z-index: 1;
    background: #fff;
    transition: all 0.2s;
    width: 100%;
    &.scrolled{
      padding-top: 20px;
      padding-bottom: 20px;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      min-height: initial;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(5px);
      @include tablet{
        backdrop-filter: none;
      }
    }
  }
  main{
    margin-top: 13vh;
    .cont_social_media_left{
      padding: 30px 10px;
      border-radius: 0 20px 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      // max-width: 3vw;
      position: fixed;
      left: 0;
      top: calc(50% - 20px);
      background: linear-gradient(to bottom, $secondary-color 20%, $primary-color 100%);
      transition: all 0.2s;
      .icon{
        font-size: 1rem;
      }
    }
  }
}
