.cont_kpi{
  display: inline-block;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 10px;
  min-width: 200px;
  .title{
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
  }
  .value{
    font-size: 1.3rem;
    font-weight: 500;
    color: #000;
    line-height: 1.5;
  }
}