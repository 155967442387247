
.cont_auth_form_view,
.cont_register_form_view,
.cont_forgot_password_form_view {
  width: 40%;
  .title{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 15px;
  }
}