.cont_my_account_page{
  h2{
    font-size: 1.5rem;
    display: block;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  .section{
    display: flex;
    justify-content: center;
    p.description{
      margin-bottom: 5px;
    }
  }
  .button{
    width: 100%;
  }
}