.cont_information_card{
  max-width: 33%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  .cont_left{
    img{
      height: auto;
      width: 75px;
    }
  }
  .cont_right{
    .title{
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 3px;
    }
    .subtitle{
      font-size: 1.1rem;
      color: rgb(70, 70, 70);
      margin-bottom: 10px;
    }
    .description{
      font-size: 0.9rem;
      color: gray;
    }
  }
}