.cont_section.section_4{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  .cont_left, .cont_right{
    width: 50%;
  }
  .cont_left{
    img{
      height: 100%;
      width: auto;
      max-height: 450px;
    }
  }
  .cont_right{
    text-align: right;
    h3{
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .c-blue{
      font-size: inherit;
    }
    .description{
      font-weight: 500;
      color: gray;
      font-size: 1rem;
      line-height: 1.3rem;

      text-align: justify;
      .bold{
        font-weight: 700;
        font-size: 1.2rem;
        color: rgb(70, 70, 70);
        margin-bottom: 10px;
        line-height: 1.2rem;
      }
    }
    ul{
      padding-left: 20px;
      li{
        text-align: justify;
        font-weight: 500;
        margin-top: 10px;
        line-height: 1.3rem;
        color: gray;
        span.bold{
          font-size: 1.2rem;
          color: rgb(70, 70, 70);
          font-weight: 700;
        }
      }
    }
  }
  @include tablet{
    flex-direction: column;

    .cont_left, .cont_right{
      width: 100%;
    }
    .cont_left{
      text-align: center;
      img{
        max-height: 300px;
      }
    }
  }
}