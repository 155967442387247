.cont_invoices_general_my_products{
  .button_multi_upload{
    float: left;
  }
  .cont_actions{
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
  .cont_filters{
    margin: 10px 0;
    .filters{
      // display: flex;
      // gap: 5px;
      .filter{
        width: 100%;
        max-width: 200px;
        margin: 0;
        margin-right: 5px;
      }
    }
    h3{
      font-size: 1.3rem;
      margin-bottom: 5px;
    }
    @include tablet{
      width: 100%;
      .filters{
        .filter{
          margin-right: 0;
          display: block;
          max-width: 100%;
        }
      }
    }
  }
}