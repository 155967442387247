.cont_left_nav{
  position: fixed;
  left: 0;
  top: 0;
  width: 200px;
  max-width: 200px;
  height: 100vh;
  z-index: 2;
  transition: all 0.2s;
  background: linear-gradient(to right, #005f9e 0%, #0092f2 40%);
  .cont_image{
    text-align: center;
    padding: 10px 0;
    img{
      width: auto;
      height: 100%;
      max-height: 7vh;
    }
  }
  .menu{
    width: 100%;
    height: 100%;
    background-color: #fffd;
    .ant-menu-submenu-selected > .ant-menu-submenu-title{
      background-color: $secondary_color;
      color: white;
    }
    .ant-menu-item-selected{
      background-color: $primary_color;
      color: white;
    }
  }
  // .cont_routes{
  //   display: flex;
  //   flex-direction: column;
  //   gap: 10px;
  //   .cont_subroutes{
  //     display: flex;
  //     flex-direction: column;
  //     gap: 5px;
  //     .cont_subroute{
  //       padding-left: 10px;
  //       padding-top: 10px;
  //       .cont_icon{
  //         width: 30px;
  //         height: 30px;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         svg{
  //           font-size: 1.5rem;
  //           color: white;
  //         }
  //       }
  //       .label{
  //         color: white;
  //         font-size: 1.2rem;
  //       }
  //     }
  //   }
  // }
}