.cont_section.section_1_prices{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  .cont_left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1{
      font-size: 3rem;
      font-weight: 700;
      padding-bottom: 10px;
      line-height: 1.2;
      text-align: justify;
      display: inline-block;
      border-bottom: 5px solid transparent;
      border-image: linear-gradient(to right, $primary-color 60%, $secondary-color 100%);
      border-image-slice: 1;
      margin-bottom: 1.5rem;
    }
    h2{
      font-size: 1.2rem;
      font-weight: 500;
      color: gray;
      text-align: justify;
      span{
        // color: rgb(0, 0, 0);
        font-size: 1.35rem;
        max-width: 90%;
      }
    }
    .btn{
      padding: 15px 30px;
      background: linear-gradient(to right, $primary-color 80%, $secondary-color 100%);
      color: #fff;
      border: none;
      border-radius: 50px 0 0 50px;
      font-size: 1.2rem;
      font-weight: 500;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;
      &:hover{
        opacity: 0.8;
      }
    }
    img.img_hombre{
      width: 100%;
      height: auto;
      margin-top: 20px;
      border-radius: 50px;
    }
  }
  .cont_right{
    width: 45%;
    display: flex;
    flex-direction: column;
    .promotion{
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 10px;
      text-align: center;
      font-style: italic;
    }
    .cont_incluye{
      margin-top: 5px;
      h3{
        font-size: 1.2rem;
        font-weight: 500;
      }
      ul{
        padding-left: 20px;
        li{
          text-decoration: none;
          list-style: none;
        }
      }
    }
    // &, .cont_table_prices{
    //   border-radius: 20px;
    // }
  }
  @include tablet{
    flex-direction: column;
    gap: 20px;
    .cont_left, .cont_right{
      width: 100%;
    }
  }
}