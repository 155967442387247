.cont_invoices_general_my_sales{
  .cont_filters{
    background-color: #fafafa;
    padding: 10px;
    margin-bottom: 1rem;
    border-radius: 5px;
    .title{
      font-size: 1.5rem;
      font-weight: 600;
    }
    .filters{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      margin-bottom: 5px;
      .filter.status > div{
        width: 100%;
      }
    }
    .submit_button{
      width: 100%;
    }
  }
  .cont_table{

  }
}