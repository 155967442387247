.cont_tax_information_page{
  .cont_form{
    display: flex;
    justify-content: space-between;
    gap: 45px;
    .cont_1, .cont_2{
      width: 50%;
      h2{
        font-size: 1.3rem;
        margin-bottom: 5px;
      }
      .cont_inputs{
      }
      .label_input{
        font-size: 1.2rem;
        display: block;
      }
    }
    .cont_2{
      .cont_inputs{
        display: grid;
        grid-template-columns: 49% 49%;
        grid-gap: 10px;
        .select{
          width: 100%;
        
        }
      }
    }
    .cont_1{
      .missing_tax_information{
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-alert-content > .ant-alert-message{
          font-size: 1.2rem;
        }
      }
      .cont_message_textarea{
        display: flex;
      }
      .cont_inputs{
        display: flex;
        gap: 10px;
        flex-direction: column;
      }
      .cont_existing_certificates{
        .cont_buttons{
          margin-top: 10px;
          display: flex;
          justify-content: flex-end;
          gap: 10px;
        }

      }
    }
  }
  .button{
    margin-top: 10px;
    width: 100%;
  }
}