.cont_footer_home{
  padding: 30px 0;
  background: linear-gradient(to right, #0092f2 80%, #1e3256 100%);
  text-align: center;
  .cont_social_media{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }
  p{
    color: white;
  }
}