.cont_section.section_1{
  height: calc(80vh - 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-right: 0;
  .cont_left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title{
      font-size: 1.5rem;
      font-weight: 600;
      padding-bottom: 20px;
      border-bottom: 5px solid transparent;
      border-image: linear-gradient(to right, $primary-color 60%, $secondary-color 100%);
      border-image-slice: 1;
      line-height: 1.6;
      margin-bottom: 1.6rem;
      span{
        font-size: 2.7rem;
        font-weight: 700;
        text-align: left;
        display: inline-block;
        max-width: 90%;
        text-transform: uppercase;
        line-height: 1.3;
      }
    }
    .description{
      font-size: 1.2rem;
      font-weight: 400;
      color: gray;
      text-align: justify;
      max-width: 90%;
      line-height: 1.5rem;
      .bold{
        font-weight: 700;
        font-size: 1.4rem;
        color: rgb(70, 70, 70);
        margin-bottom: 10px;
      }
    }
    .btn{
      padding: 15px 30px;
      background: linear-gradient(to right, $primary-color 80%, $secondary-color 100%);
      color: #fff;
      border: none;
      border-radius: 50px 0 0 50px;
      font-size: 1.2rem;
      font-weight: 500;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;
      &:hover{
        opacity: 0.8;
      }
    }
  }
  .cont_right{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    img{
      max-height: 80%;
      width: 100%;
      object-fit: cover;
      object-position: left center;
    }
    .circle{
      position: absolute;
      .circle_1{
        width: 300px;
        height: 300px;
        background: #fff;
        clip-path: circle(50%);
        filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
      }
    }
  }
  @include tablet{
    flex-direction: column;
    gap: 20px;
    padding-right: 0;
    justify-content: flex-start;
    height: auto;
    .cont_left{
      width: 100%;
      h1{
        font-size: 1.2rem;
        span{
          font-size: 2rem;
        }
      }
      h2{
        font-size: 1rem;
      }
      .btn{
        padding: 10px 20px;
        font-size: 1rem;
      }
    }
    .cont_right{
      width: 100%;
      height: 50%;
      img{
        max-height: 200px;
        border-radius: 10px;
      }
      .circle{
        .circle_1{
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}