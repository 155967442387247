.cont_login_form{
  .cont_forgot_password{
    text-align: right;
    margin-top: 10px;
    .anchor{
      font-size: 0.8rem;
      color: $primary-color;
      text-decoration: none;
    }
  }
}