.cont_section.section_2{
  height: auto;
  min-height: calc(20vh - 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  align-items: stretch;
  @include tablet{
    flex-direction: column;
    .cont_information_card{
      max-width: fit-content;
    }
  }
}