
@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 480px) {
    @content;
  } 
}

@mixin desktop {
  @media (min-width: 769px) {
    @content;
  }
}