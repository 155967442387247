.cont_left_nav_button{
  text-decoration: none;
  &.active{
    background: white;
    .cont_left{
      svg{
        color: $primary_color;
      }
    }
    .cont_right{
      .label{
        color: $primary_color;
      }
    }
  }
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 15px;
  .cont_left{
    svg{
      color: white;
    }
  }
  .cont_right{
    .label{
      color: white;
      font-size: 1.2rem;
    }
  }
}