.cont_header_dashboard{
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 200px);
  height: 7vh;
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  z-index: 10;
  & > div{
    height: 100%;
    display: inline-block;
  }
  .cont_sections{
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
  }
  .buttons_right{
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
  }
}