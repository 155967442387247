.cont_settings_general_page{
  .cont_form{
    display: flex;
    justify-content: space-between;
    gap: 45px;
    .cont_1, .cont_2{
      width: 50%;
      h2{
        font-size: 1.3rem;
        margin-bottom: 5px;
      }
      .label_input{
        font-size: 1.2rem;
        display: block;
      }
    }
  }
  .button{
    margin-top: 10px;
    width: 100%;
  }
}