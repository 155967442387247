.cont_comp_header_header_home{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .left{
    display: flex;
    align-items: center;
    .logo{
      width: 20%;
      height: auto;
      max-width: 700px;
      transition: all 0.2s;
    }
    ul{
      display: flex;
      gap: 30px;
      li{
        list-style: none;
        a{
          text-decoration: none;
          color: #000;
          font-size: 1.2rem;
          font-weight: 500;
          &:hover{
            color: #0092f2;
          }
        }
      }
    }
  }
  .right{
    .btn_login{
      padding: 15px 30px;
      background: linear-gradient(to right, #0092f2 80%, #1e3256 100%);
      color: #fff;
      border: none;
      border-radius: 50px 0 0 50px;
      font-size: 1.2rem;
      font-weight: 500;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;
      &:hover{
        opacity: 0.8;
      }
    }
  }
  &.scrolled{
    .left{
      .logo{
        width: 15%;
      }
      ul{
        gap: 20px;
        li{
          a{
            font-size: 1rem;
          }
        }
      }
    }
    .right{
      .btn_login{
        font-size: 1rem;
      }
    }
  }
  @include tablet{
    justify-content: flex-end;
    margin-right: 20px;
    .button_menu{
      padding: 10px;
      z-index: 3;
      .ant-btn-icon{
        svg{
          font-size: 2.5rem;
          color: $primary-color;
        }
      }
    }
    .menu_mobile{
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 40%;
      background: #fff;
      transition: all 0.3s;
      z-index: 2;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(5px);
      &.hide{
        height: 0;
        overflow: hidden;
      }
      &.active{
        right: 0;
      }
      ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0;
        gap: 20px;
        li{
          list-style: none;
          a{
            text-decoration: none;
            color: #000;
            font-size: 1.5rem;
            font-weight: 500;
            &:hover{
              color: #0092f2;
            }
          }
          .btn_login{
            padding: 15px 30px;
            background: linear-gradient(to right, #0092f2 80%, #1e3256 100%);
            color: #fff;
            border: none;
            border-radius: 50px;
            font-size: 1.2rem;
            font-weight: 500;
            white-space: nowrap;
            cursor: pointer;
            transition: all 0.3s;
          }
        }
      }
    }
  }
}