.cont_automatization_page{
  h2{
    font-size: 1.3rem;
    margin-bottom: 5px;
  }
  .cont_processes{
    display: flex;
    gap: 10px;
    & > div{
      width: 50%;
    }
    .cont_message_textarea{
      display: flex;
      margin-bottom: 5px;
    }
  }
  .button{
    margin-top: 10px;
    width: 100%;
  }
}