.cont_form_component{
  .ant-form-item{
    margin-bottom: 10px;
    .ant-form-item-label{
      padding: 0;
      margin-bottom: 2px;
    }
  }
  .ant-input-affix-wrapper{
    margin-bottom: 0;
  }
  .cont_footer{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    & > .ant-form-item{
      margin-bottom: 0;
    }
  }
  @include phone{
    .cont_footer{
      flex-direction: column;
      & > .ant-form-item{
        width: 100%;
        gap: 5px;
        button{
          width: 100%;
        }
      }
    }
  }
}