.cont_generate_global_invoice_page{
  h2{
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  .cont_form{
    .cont_inputs{
      display: flex;
      gap: 15px;
      .select{
        margin-bottom: 5px;
      }
      &>div{
        width: 50%;
      }
    }
    button{
      margin-top: 10px;
      width: 100%;
    }
  }

  .cont_detail_sales {
    margin-top: 20px;
    table{
      border-collapse: separate;
      border-spacing: 5px;
      width: 100%;
      td{
        padding: 5px 0;
        text-align: center;
      }
      tbody{
        tr{
          td{
            text-align: center;
          }
        }
      }
      tfoot{
        tr{
          &:first-child{
            td{
              padding-top: 20px;
            }
          }
          td:first-child{
            font-weight: bold;
            text-align: right;
          }
        }
      }
    }
  }
}