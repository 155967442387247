@import "_normalizer.scss", "./breakpoints.scss", "./variables";
@import "./app/index.scss", "./components/index.scss", "./layouts/index.scss";



button, a{
  transition: all 0.2s;
  
  &:hover{
    cursor: pointer;
    opacity: 0.8;
  }
}
.c-blue{
  color: #0092f2;
}
.opacity_0{
  opacity: 0;
}
#cont_loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 253, 253, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .img{
    width: 200px;
    height: 200px;
    // Add animation invisible
    animation: invisible 2.5s infinite ease-in-out;
  }
  h4{
    margin-top: 10px;
    font-size: 2rem;
  }
}

.warning_if_error_data{
  margin-top: 20px !important;
  p{
    font-size: 1.1rem;
    i.example{
      font-size: 0.9rem;
    }
  }
}
@keyframes invisible {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
