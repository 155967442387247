.cont_invoices_store_page{
  .cont_prices{
    display: flex;
    justify-content: center;
    table.cont_table_prices{
      max-width: 400px;
    }

  }
  .cont_form_buy{
    max-width: 400px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    h2{
      font-size: 1.5rem;
      margin-bottom: 5px;
    }
    .cont_inputs{
      margin-top: 5px;
      span.label_input{
        display: block;
        font-size: 1.2rem;
      
      }
    }
    table.cont_table_summary{
      td{
        padding: 5px 10px;
        text-align: center;
      }
      tfoot{
        tr{
          &:first-child{
            td{
              padding-top: 20px;
            }
          }
          td:first-child{
            font-weight: bold;
            text-align: right;
          }
        }
      }
    }
    .button{
      width: 100%;
      margin-top: 5px;
    }
  }
}