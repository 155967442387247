.cont_custom_button{
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding: 10px;
  background-color: $primary_color;
  border-color: transparent;
  .icon{
    width: auto;
    height: 25px;
  }
  .text{
    color: white;
    font-size: 1.2rem;
  }
} 
